export default function (value) {
  if (!value) return value
  let names = ''

  for (let index = 0; index < value.length; index++) {
    names += value[index].name
    if (index < value.length - 1) {
      names += ', '
    }
  }
  return names
}
