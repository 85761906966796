<template>
  <div>
    <ca-header
      heading="Versichererabrechnung"
      :column-layout="true"
    />
    <div class="col-12 col-xl-8 col-xxl-6 py-4">
      <CaCard class="mb-3">
        <template #header>
          <h4 class="mb-0">
            Versichererabrechnung
          </h4>
        </template>
        <template #body>
          <form
            novalidate
            @submit.prevent="createVersichererabrechnung"
          >
            <basic-file-upload
              v-model="versichererabrechnung.file"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              placeholder="Anhang hochladen"
              :horizontal="true"
              label="Dokumentenupload"
              accept=".xlsx, .xls"
              :align-label-center="false"
              required
            />
            <div class="row">
              <div class="col-12 col-md-4">
                Versicherer
              </div>
              <div class="col-12 col-md-8">
                <div
                  v-for="(versicherer, index) in versichererList"
                  :key="versicherer + index"
                >
                  <div>
                    {{ versicherer.label }} - {{ versicherer.value }}%
                  </div>
                </div>
              </div>
            </div>
            <button
              class="btn btn-primary float-right"
              type="submit"
              :disabled="pendingCreateVersichererabrechnung"
            >
              Abrechnung erzeugen
              <i
                v-if="pendingCreateVersichererabrechnung"
                class="fas fa-spinner-third fa-spin"
              />
            </button>
          </form>
        </template>
      </CaCard>
    </div>
    <div class="container-fluid pt-2">
      <CaCard>
        <template #header>
          <h4 class="mb-0">
            Vorherige Versichererabrechnungen
          </h4>
        </template>
        <template #body>
          <DataTable
            ref="dataTable"
            :fields="fields"
            service="versichererabrechnungen"
            title="Versichererabrechnungen"
            :base-query="baseQuery"
            @row-clicked="(item) => openModal(item)"
          />
        </template>
      </CaCard>
    </div>
    <modal
      v-if="showModal"
      size="md"
      title="Versicherungsabrechnung"
      @close="showModal = false"
    >
      <template
        #body
      >
        <div class="row p-2 border-bottom border-gray-1">
          <div class="font-weight-bold col-4">
            Name
          </div>
          <div class="font-weight-bold col-4">
            Anteil
          </div>
          <div class="font-weight-bold col-4 text-right">
            Versichererdatei
          </div>
        </div>
        <div
          v-for="versicherer in versicherungInModal.versicherer"
          :key="versicherer.label"
        >
          <div class="row p-2">
            <div class=" col-4">
              {{ versicherer.name }}
            </div>
            <div class=" col-4">
              {{ versicherer.anteil }}%
            </div>
            <SignedDownloadLink
              v-if="versicherer.versicherungsabrechnungsFile"
              :key="versicherer.versicherungsabrechnungsdateiId"
              class=" col-4 text-right"
              :file="versicherer.versicherungsabrechnungsFile"
            >
              <template #default="{link}">
                <a
                  class="cursor-pointer"
                  :href="link"
                  :download="versicherer.versicherungsabrechnungsFile.name"
                >
                  Herunterladen
                </a>
              </template>
            </SignedDownloadLink>
          </div>
          <div class="border border-top-1 border-gray-1" />
        </div>

        <div class=" mt-3">
          <div>Erstellt von: {{ versicherungInModal.user.firstname }} {{ versicherungInModal.user.lastname }}</div>
          <div>Erstellt am: {{ formatDate(versicherungInModal.createdAt, 'DD.MM.YYYY HH:mm') }}</div>
          <div>
            Uploaddatei:
            <SignedDownloadLink
              :key="versicherungInModal.uploaddateiId"
              :file="versicherungInModal.uploadFile"
            >
              <template #default="{link}">
                <a
                  class="cursor-pointer"
                  :href="link"
                >
                  Herunterladen
                </a>
              </template>
            </SignedDownloadLink>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import CaCard from '@/components/Card'
import BasicFileUpload from '@/components/BaseComponents/BasicFileUpload.vue'
import feathers from '@/api'
import DataTable from '@/components/DataTable/DataTable'
import formatDate from '../../filters/formatDate.js'
import SignedDownloadLink from '@/components/SignedDownloadLink.vue'
import Modal from '@/components/Modal'
import formatVersichererName from '../../filters/formatVersichererNames'

export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    CaHeader,
    CaCard,
    BasicFileUpload,
    DataTable,
    SignedDownloadLink,
    Modal
  },
  filters: {
    formatDate
  },
  data: () => ({
    versichererList: [],
    versichererabrechnung: {},
    versicherungInModal: {},
    showModal: false,
    pendingCreateVersichererabrechnung: false,
    fields: [
      {
        label: 'Versicherer',
        key: 'versicherer',
        formatter: value => formatVersichererName(value)
      },
      {
        label: 'Erstellt von',
        key: 'user',
        formatter: value => `${value.firstname} ${value.lastname}`
      },
      {
        label: 'Erstellt am',
        key: 'createdAt',
        formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
        filter: {
          type: 'DATERANGE'
        }
      }
    ]
  }),
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    },
    baseQuery () {
      return {
        $client: {
          $lookup: {
            versichererName: this.$route.query.search
          }
        }
      }
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    formatDate,
    async fetchData () {
      const versichererReq = await this.$store.dispatch('versicherer/find')

      this.versichererList = versichererReq.data
        .filter(versicherer => versicherer.status)
        .map(versicherer => {
          return { label: versicherer.name, value: versicherer.anteil, versichererId: versicherer._id }
        })
    },
    async createVersichererabrechnung () {
      const valid = await this.$validator.validateAll()
      if (!valid || !this.versichererabrechnung.file._id || this.pendingCreateVersichererabrechnung) {
        return
      }
      for (const versicherer of this.versichererList) {
        versicherer.versicherungsabrechnungsdateiId = this.versichererabrechnung.file._id
      }
      try {
        this.pendingCreateVersichererabrechnung = true
        const resp = await feathers.service('versichererabrechnungen').create({
          // versicherer: this.versichererList,
          createdFromId: this.me._id,
          uploaddateiId: this.versichererabrechnung.file._id
        })
        // await this.fetchData()
        this.openModal(resp)
      } catch (e) {
        console.error(e)
      } finally {
        this.pendingCreateVersichererabrechnung = false
      }
    },
    openModal (item) {
      this.showModal = true
      this.versicherungInModal = item
    }
  }
}
</script>
